// Slide animation
// Add some element class .make-slide
// slide target is defined in href or data-to-slide attr
// set offset in attr data-offset in px "120"

export function initSlide  () {
	if($(".js-slide").length > 0){
		$(document).on("click touch", ".js-slide", function (e) {
			e.preventDefault();


			var offset = $(this).attr("data-offset");

			if (typeof offset !== undefined && offset !== false && !isNaN(offset)) {
				offset = parseInt(offset);
			} else {
				offset = 0;
			}

			if ($(this).attr("href") !== undefined) {
				var slideTo = $(this).attr("href");
			} else {
				var slideTo = $(this).attr("data-to-slide");
			}


			var slideToArray = decodeURIComponent(slideTo).split("+");
			if(slideToArray.length > 1){
				$('a[href=\\'+slideToArray[0]+']').tab('show');
				$('a[href=\\'+slideToArray[0]+']').on('shown.bs.tab', function (e) {
					e.target // newly activated tab
					e.relatedTarget // previous active tab

					var scroll_px = $(slideToArray[1]).offset().top + offset;
					$('html,body').animate({scrollTop: scroll_px}, 'slow');
				});

			} else {
				var scroll_px = $(slideToArray[0]).offset().top + offset;
				$('html,body').animate({scrollTop: scroll_px}, 'slow');
			}


		});
	}
};

// #param string "#selector"
// #param int offset
export function slideTo (element, offset) {
	var offset = offset || 0;
	var el = $(element);
	if (el.length > 0) {
		var scroll_px = el.offset().top - offset;
		$('html,body').animate({scrollTop: scroll_px}, 'slow');
	}
};
