var scrollMonitor = require("scrollmonitor");


export function initNav () {

	var elWithClass = $("body");


	if (!($(".nav-transparent-section").length > 0)) {
		elWithClass.removeClass("nav-transparent");
		elWithClass.addClass("nav-black");
		return
	}

	if (elWithClass.hasClass("nav-transparent")) {
		try {
			var myElement = $(".nav-transparent-section");
			var elementWatcher = scrollMonitor.create(myElement, -60);
			var btn_wrapper = $(".navigation");
			var btn_1 = btn_wrapper.find(".btn-black-green");
			var btn_2 = btn_wrapper.find(".btn-ghost-green");

			elementWatcher.enterViewport(function () {
				if(!$("#navbar-mobile").hasClass("show")){
					elWithClass.removeClass("nav-scrolled");
					btn_1.removeClass("btn-green").addClass("btn-black-green");
					btn_2.removeClass("btn-ghost-white").addClass("btn-ghost-green");
				}
			});
			elementWatcher.exitViewport(function () {
				elWithClass.addClass("nav-scrolled");
				btn_1.removeClass("btn-black-green").addClass("btn-green");
				btn_2.removeClass("btn-ghost-green").addClass("btn-ghost-white");
			});
		} catch(e){
			console.error(e.message);
		}
	}


	$("#burger").on("click", function(){
		$("#burger").toggleClass("is-active");

		if(!$(".nav-transparent").hasClass("nav-scrolled")){
			$(".nav-transparent").addClass("nav-scrolled");


			var btn_wrapper = $(".navigation");
			var btn_1 = btn_wrapper.find(".btn-black-green");
			var btn_2 = btn_wrapper.find(".btn-ghost-green");

			btn_1.removeClass("btn-black-green").addClass("btn-green");
			btn_2.removeClass("btn-ghost-green").addClass("btn-ghost-white");
		}
	});

};

/**
 *
 * element - nav element
 * */
export function initHiddingNav (element) {
	var nav = $(element);

	function hasScrolled() {
		var st = $(window).scrollTop();

		// Make sure they scroll more than delta
		if(Math.abs(lastScrollTop - st) <= delta)
			return;

		// If they scrolled down and are past the navbar, add class .nav-up.
		// This is necessary so you never see what is "behind" the navbar.
		if (st > lastScrollTop && st > navbarHeight){
			// Scroll Down
			/*
			if($('#navbar').hasClass("in")){
				$("#nav-icon1").trigger("click");
			}else if($('#navbar2').hasClass("in")){
				$("#nav-icon2").trigger("click");
			}*/

			nav.removeClass('nav-down').addClass('nav-up');


		} else {
			// Scroll Up
			if(st + $(window).height() < $(document).height()) {

				nav.removeClass('nav-up').addClass('nav-down');
			}
		}

		lastScrollTop = st;
	}

	if(nav.length /*&& $(window).width() > 768*/){

		// Hide Header on on scroll down
		var didScroll;
		var lastScrollTop = 0;
		var delta = 5;
		var navbarHeight = nav.outerHeight();


		$(window).scroll(function(event){
			didScroll = true;
		});

		setInterval(function() {
			if (didScroll) {
				hasScrolled();
				didScroll = false;
			}
		}, 250);

	}
}


/**
 *
 * element - nav element
 * siblingElement - sibling for adding padding top if nav is fixed
 * */
export function  initLowerFixedNav (element,siblingElement) {
	const nav = $(element);

	if (nav.length > 0) {
		var watcher = scrollMonitor.create(nav);
		watcher.lock();
		watcher.stateChange(function () {
			nav.toggleClass('fixed-top', this.isAboveViewport)
			console.log(this.isAboveViewport);
			if(this.isAboveViewport){
				if(typeof $(siblingElement) != "undefined"){
					$(siblingElement).css("padding-top", nav.outerHeight());
				}
			}else{
				if(typeof $(siblingElement) != "undefined"){
					$(siblingElement).css("padding-top", 0);
				}
			}
		});
	}

};
