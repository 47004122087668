
global.LazyLoad = require('vanilla-lazyload/dist/lazyload.min.js');
//https://scottjehl.github.io/picturefill/#examples
require('picturefill');

export let lazyImages, lazyBackgrounds, lazyIframes;

export function initLazyLoad () {
	var galleryReinit = false;

	var myLazyLoad = new LazyLoad({
		threshold: 1000,
		callback_load: function(el){
			if(!galleryReinit && $(el).hasClass("g-item")){
				//console.log("reinit gallery");
				app.initGalleryTour();
				galleryReinit = true;
			}
		},
	});

	var myLazyLoad2 = new LazyLoad({
		elements_selector: ".lazy"
	});

	var myLazyLoad3 = new LazyLoad({
		threshold: 50,
		elements_selector: ".lazyIframe"
	});

	lazyImages = myLazyLoad;
	lazyBackgrounds = myLazyLoad2;
	lazyIframes = myLazyLoad3;

	picturefill();

	//Polyfill object-fit/object-position on <img>
	objectFitImages();


	return myLazyLoad;

};

// @param selector of images to load
export function initLazyImages (selector) {
	var imgs = $(selector);
	var imgLength = imgs.length;
	for (var i = 0, l = imgLength; i < l; i++) {
		lazyImages.load(imgs[i]);
		lazyBackgrounds.load(imgs[i]);
		lazyIframes.load(imgs[i]);
	}
};

export function loadModalLazyImages() {
	var modalLazyLoad;
	$('.modal')
		.bind('shown.bs.modal', function () {
			modalLazyLoad = new LazyLoad({
				container: this
			});
		})
		.bind('hidden.bs.modal', function () {
			modalLazyLoad.destroy();
		});
}
