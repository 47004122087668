import style from "../scss/index.scss";


global.$ = global.jQuery = require('jquery');
require('popper.js');
require('bootstrap');
global.Nette = require('nette-forms');
require('nette.ajax.js');
//require('../invisibleRecaptcha.js');
global.LazyLoad = require('vanilla-lazyload/dist/lazyload.min.js');
require('picturefill');
global.objectFitImages = require('object-fit-images');

import { jarallax, jarallaxVideo } from "jarallax";

jarallaxVideo();

let videos = document.querySelectorAll('.jarallax');
if(videos.length > 0) {
	jarallax(videos , {
		speed: 0.2,
	});
}


//require('lightgallery');
//require('lg-video');
//equire('lg-thumbnail');
//require('lg-zoom');

//global.justifiedGallery = require('justifiedGallery');


import * as base from './sub/all.js';


window.app = base;

(function () {
	document.addEventListener("DOMContentLoaded", function(){
		if (typeof $.nette.inner === 'undefined' || $.nette.inner === null) {
			$.nette.init();
		}
	});
})();
