require('lightgallery');
require('lg-video');
require('lg-thumbnail');
require('lg-zoom');
require('scrollmonitor');
global.justifiedGallery = require('justifiedGallery');

export let lightBoxGallery;

export function initGalleryTour () {
	var gallery = $("#tour-detail-gallery");

	try { gallery.justifiedGallery('destroy'); }catch(ex){};

	var timeOut = null;


	var startInitGallery = function() {
		gallery.justifiedGallery({
			rowHeight: 235,
			waitThumbnailsLoad: true,
			selector: '.gallery-item',
			margins: 0,
			captions: false
		}).on('jg.complete', function (e) {
			gallery.find(".block-loader").removeClass("in");
		});

		if($('#tour-detail-gallery .gallery-item').length > 0){

			gallery.lightGallery({
				selector: ".gallery-item",
				download: window.enableDownload,
				preload: 2,
				mousewheel: false,
				getCaptionFromTitleOrAlt: false,
				hideBarsDelay: 2000,
				subHtmlSelectorRelative: true,
				thumbnail: true,
				showThumbByDefault: false,
				exThumbImage: 'data-exthumbimage'
			});

		}
	};


	if(gallery.find(".gallery-item img").eq(1).data("was-processed")){
		startInitGallery();
		clearInterval(timeOut);
	}else{

		var timeOut = setInterval(function(){
			if(gallery.find(".gallery-item img").eq(1).data("was-processed")){
				startInitGallery();
				clearInterval(timeOut);
			}
			//console.log("iinterval");
		}, 600);

	}

	var galeryWrapper = gallery.closest(".gallery");
	var btnSection = galeryWrapper.find(".js-gallery-btn");



	$(document).off("click",".more-photo");
	$(document).on("click", ".more-photo", function (e) {
		e.preventDefault();
		var galeryWrapper = gallery.closest(".gallery");
		gallery.toggleClass("gallery-visible");
		galeryWrapper.toggleClass("js-gallery-open");
		var text = $(this).find(".txt").text();

		var lessText = $(this).attr("data-less");
		var moreText = $(this).attr("data-more");


		if(window.innerWidth < 993) {
			btnSection.toggleClass("fixed-bottom");

			var watcher = scrollMonitor.create(galeryWrapper);
			watcher.lock();
			watcher.recalculateLocation();


			if(text == lessText) {
				btnSection.removeClass('fixed-bottom');

				$('html,body').animate({
						scrollTop: galeryWrapper.offset().top - 100
					},
					'slow'
				);
			} else {
				watcher.stateChange(function () {
					if(this.isInViewport) {
						btnSection.addClass('fixed-bottom');

						if(!this.isFullyInViewport && this.isAboveViewport){
							btnSection.removeClass('fixed-bottom');
						}

					}  else if(!this.isFullyInViewport){
						btnSection.removeClass('fixed-bottom');
					} else {
						btnSection.removeClass('fixed-bottom');
					}
				});
			}
		}


		$(this).find(".txt").text(text == moreText ? lessText : moreText);

	});

};

//@param jquery object
export function initGallery (gallery_element) {

	if($('.gallery-item').length > 0) {


		var $sl = $('#main_content');
		try { $sl.lightGallery(); $sl.data('lightGallery').destroy(true); }catch(ex){};

		$sl.lightGallery({
			selector: ".gallery-item",
			hideBarsDelay: 2000,
			download: window.enableDownload,
			preload: 2,
			mousewheel: false,
			thumbnail: true,
			showThumbByDefault: false,
			exThumbImage: 'data-exthumbimage',
			loadVimeoThumbnail: true,
			vimeoThumbSize: 'thumbnail_large',
			videoMaxWidth: '1000px',
			vimeoPlayerParams: {
				byline : 0,
				portrait : 0,
				color : 'A90707',
				title: false,
				quality: "720p"
			}
		});

		if($(".more-photo").length > 0){
			$(document).on("click", ".more-photo", function (e) {
				e.preventDefault();
				gallery_element.toggleClass("gallery-visible");
				var text = $(this).find(".txt").text();
				$(this).find(".txt").text(text == "Zobraziť viac" ? "Zobraziť menej" : "Zobraziť viac");
				e.preventDefault();
			});
		}

		lightBoxGallery = $sl;

	}
};
