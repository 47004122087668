import 'keen-slider/keen-slider.min.css'
import KeenSlider from 'keen-slider'

function createSlider(wrapper, options) {


	if (options !== undefined) {
		var breakpointsOptions = options
	} else {
		var breakpointsOptions = {
			'(min-width:0px) and (max-width: 575px)': {
				slidesPerView: 1,
				spacing: 100,
				centered: true,
				loop: true,
				mode: "free-snap",
			},
			'(min-width:576px) and (max-width: 767px)': {
				slidesPerView: 1,
				spacing: 100,
				centered: true,
				loop: true,
				mode: "free-snap",
			},
			'(min-width: 768px) and (max-width: 991px)': {
				slidesPerView: 2,
				spacing: 100,
				centered: true,
				loop: true,
				mode: "free-snap",
			},
			'(min-width: 992px) and (max-width: 1199px)': {
				slidesPerView: 2,
				spacing: 100,
				centered: true,
				loop: true,
				mode: "free-snap",
			},
			'(min-width: 1200px) and (max-width: 1399px)': {
				slidesPerView: 2,
				spacing: 100,
				centered: true,
				loop: true,
				mode: "free-snap",
			},
			'(min-width: 1400px)': {
				slidesPerView: 2,
				spacing: 100,
				centered: true,
				loop: true,
				mode: "free-snap",
			}
		};
	}

	var slides = wrapper.querySelectorAll(".keen-slider__slide");
	var slidesToLoad = Array.from(slides, x => false);
	var beforeIndex = 0;


	new KeenSlider(wrapper.querySelector(".keen-slider"), {
		breakpoints: breakpointsOptions,
		created: function (instance) {
			wrapper
				.querySelector(".carousel_arrow--left")
				.addEventListener("click", function () {
					instance.prev();
				});

			wrapper
				.querySelector(".carousel_arrow--right")
				.addEventListener("click", function () {
					instance.next();
				});

			var dots_wrapper = wrapper.querySelector(".carousel_dots");
			var slides = wrapper.querySelectorAll(".keen-slider__slide");
			if(dots_wrapper !== null){
				slides.forEach(function (t, idx) {
					var dot = document.createElement("button");
					dot.classList.add("carousel_dot");
					dots_wrapper.appendChild(dot);
					dot.addEventListener("click", function () {
						instance.moveToSlide(idx);
					});
				});
			}

			updateClasses(instance);
		},
		slideChanged(s) {

			updateClasses(s);
			let slideIdx = s.details().relativeSlide;
			let slideIdxOffset;
			let loadMoreSlide = true;
			let slidesLen = slides.length;

			if(loadMoreSlide && (slideIdx === (slidesLen -1) || beforeIndex > slideIdx) ) {
				slideIdxOffset = slideIdx-1;
			} else if(loadMoreSlide && slideIdx > beforeIndex){
				slideIdxOffset = slideIdx + s.details().slidesPerView;
			}

			if(slideIdx > 0) {
				if(!slidesToLoad[slideIdx] && typeof slidesToLoad[slideIdx] != "undefined") {
					loadImages(s,slideIdx);
				}

				if(loadMoreSlide && !slidesToLoad[slideIdxOffset] && typeof slidesToLoad[slideIdxOffset] != "undefined") {
					loadImages(s,slideIdxOffset);
				}
			}

			beforeIndex = slideIdx;
		}
	});


	function loadImages(instance, indexToLoad) {
		var images = slides[indexToLoad].querySelectorAll('.lazy');

		for (var i=0, il = images.length; i < il; i++) {

			if(images[i].tagName == "IMG" || images[i].tagName == "IFRAME") {
				images[i].setAttribute("src", images[i].getAttribute("data-src"));
			} else {
				images[i].setAttribute("style", "background-image: url(" + images[i].getAttribute('data-src') + ")");
			}

		}

		slidesToLoad[indexToLoad] = true;
	}

	function updateClasses(instance) {
		var slide = instance.details().relativeSlide;
		var arrowLeft = wrapper.querySelector(".carousel_arrow--left");
		var arrowRight = wrapper.querySelector(".carousel_arrow--right");
		!instance.details().loop && instance.details().relativeSlide === 0
			? arrowLeft.classList.add("carousel_arrow--disabled")
			: arrowLeft.classList.remove("carousel_arrow--disabled");
		!instance.details().loop && instance.details().relativeSlide + instance.details().slidesPerView === instance.details().size
			? arrowRight.classList.add("carousel_arrow--disabled")
			: arrowRight.classList.remove("carousel_arrow--disabled");

		var dots = wrapper.querySelectorAll(".carousel_dot");
		if(dots !== null) {
			dots.forEach(function (dot, idx) {
				idx === slide
					? dot.classList.add("carousel_dot--active")
					: dot.classList.remove("carousel_dot--active");
			});
		}

	}

}

export function initSliders() {
	document.querySelectorAll(".slider-wrapper").forEach((wrapper) => {
		createSlider(wrapper);
		console.log("create slider!")
	});
}

export function initSlider(element, options) {
	createSlider(element, options);
}


